import { useState } from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridOverlay, GridPaginationModel } from '@mui/x-data-grid';

import { Checkbox } from 'components/_common/DataGrid/Checkbox';
import { dataGridStyle } from 'components/_common/DataGrid/dataGridStyling';
import { Pagination } from 'components/_common/DataGrid/Pagination';
import { PageHeader } from 'components/_common/PageHeader';

import OrderColumns from './components/OrderColumns';
import { orders } from './mockData';

export const Orders = () => {
  const defaultPageSize = 25;

  const dataGridClasses = dataGridStyle();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    setPage(data.page);
  };

  function generateRandomId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  const handleOrderDetailsClick = (params: any) => {
    console.log('Order Details Clicked');
  };

  const handleViewCustomerClick = (params: any) => {};

  const columns = OrderColumns({ handleOrderDetailsClick, handleViewCustomerClick });
  return (
    <Box>
      <PageHeader title="Sales Orders" testId={'SalesOrders'} />
      <Box>
        <DataGrid
          paginationMode="server"
          onPaginationModelChange={handlePaginationChanges}
          rows={orders.items ?? []}
          rowHeight={37}
          columns={columns}
          autoHeight
          onRowClick={(params) => handleOrderDetailsClick(params)}
          getRowId={() => generateRandomId()}
          rowCount={orders.itemCount ?? 0}
          columnVisibilityModel={{
            partner: false,
            brand: false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                page: page,
                pageSize: pageSize,
              },
            },
          }}
          components={{
            LoadingOverlay: () => <GridOverlay sx={{ backgroundColor: 'white', opacity: 0.5 }} />,
            Pagination: () => <Pagination disablePaginationButtons={orders.items.length === 0} />,
            BaseCheckbox: Checkbox,
          }}
          sx={{
            '& .MuiDataGrid-row': {
              '&:hover': {
                '& .menuOptionButton': {
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 2,
                },
              },
            },
          }}
          className={dataGridClasses.root}
        />
      </Box>
    </Box>
  );
};
