import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { TransactionStatus as TransactionStatusModel } from '@one/api-models/lib/Admin/Transaction/TransactionStatus';

import { dateRangeFilterOperators } from 'components/_common/DataGrid/FilterDateInput';
import { nameFilterOperators } from 'components/_common/DataGrid/FilterNameInput';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { ActionMenu } from 'components/views/transactions/components/ActionMenu';
import { TransactionStatus } from 'components/views/transactions/components/TransactionStatus';

interface OrderColumnsProps {
  handleOrderDetailsClick: (params: any) => void;
  handleViewCustomerClick: (params: any) => void;
}

const OrderColumns = ({ handleOrderDetailsClick, handleViewCustomerClick }: OrderColumnsProps) => {
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const { showMessage } = useToastMessage();

  const columns: GridColDef[] = [
    {
      field: 'partner',
      headerName: 'Partner',
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (params: any) => params?.row?.customer?.partnerKey,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.partnerKey}</Typography>
      ),
    },
    {
      field: 'brand',
      headerName: 'Brand',
      sortable: false,
      filterable: false,
      width: 65,
      disableColumnMenu: true,
      type: 'string',
      valueGetter: (params: any) => params?.row?.customer?.brandKey,
      renderCell: (params: any) => <Typography sx={{ fontWeight: 600 }}>{params?.row?.customer?.brandKey}</Typography>,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      filterable: false,
      minWidth: 110,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'center',
      type: 'number',
      valueGetter: (params: any) => params?.row?.orderTotal?.amount,
      renderCell: (params: any) => (
        <Typography sx={{ fontWeight: 600, color: 'black' }}>
          {formatCurrency(params?.row?.orderTotal?.amount, params?.row?.orderTotal?.currency)}
        </Typography>
      ),
    },
    {
      field: 'Currency',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 40,
      type: 'string',
      disableColumnMenu: true,
      valueGetter: (params: any) => params?.row?.orderTotal?.currency,
      renderCell: (params: any) => <>{params?.row?.orderTotal?.currency}</>,
    },
    {
      field: 'Status',
      headerName: '',
      sortable: false,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: TransactionStatusModel.New, label: 'New' },
        { value: TransactionStatusModel.Succeeded, label: 'Succeeded' },
        { value: TransactionStatusModel.Failed, label: 'Failed' },
        { value: TransactionStatusModel.Rejected, label: 'Rejected' },
        { value: TransactionStatusModel.Pending, label: 'Pending' },
        { value: TransactionStatusModel.PendingCapture, label: 'PendingCapture' },
        { value: TransactionStatusModel.Cancelled, label: 'Cancelled' },
        { value: TransactionStatusModel.Expired, label: 'Expired' },
        { value: TransactionStatusModel.PendingRefund, label: 'PendingRefund' },
        { value: TransactionStatusModel.Refunded, label: 'Refunded' },
        { value: TransactionStatusModel.Blocked, label: 'Blocked' },
        { value: TransactionStatusModel.Disputed, label: 'Disputed' },
        { value: TransactionStatusModel.EarlyFraudWarning, label: 'EarlyFraudWarning' },
        { value: TransactionStatusModel.Incomplete, label: 'Incomplete' },
        { value: TransactionStatusModel.PartiallyPaid, label: 'PartiallyPaid' },
        { value: TransactionStatusModel.PartiallyRefunded, label: 'PartiallyRefunded' },
        { value: TransactionStatusModel.Uncaptured, label: 'Uncaptured' },
        { value: TransactionStatusModel.WaitingOnFunding, label: 'WaitingOnFunding' },
        { value: TransactionStatusModel.Deleted, label: 'Deleted' },
      ],
      width: 156,
      disableColumnMenu: true,
      valueGetter: (params: any) => params?.row?.status,
      renderCell: (params: any) => <TransactionStatus status={params.row.status} />,
    },
    {
      field: 'customerKey',
      headerName: 'Customer Key',
      filterable: true,
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      width: 200,
      valueGetter: (params: any) => params?.row?.customer?.memberKey,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params?.row?.customer?.memberKey}
        </Typography>
      ),
    },
    {
      field: 'customer',
      headerName: 'Customer',
      filterable: true,
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      width: 200,
      valueGetter: (params: any) => `${params?.row?.customer.firstName} ${params?.row?.customer.lastName}`,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {`${params?.row?.customer.firstName} ${params?.row?.customer.lastName}`}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      filterable: false,
      width: 350,
      type: 'string',
      disableColumnMenu: true,
      valueGetter: (params: any) => params.row.orderDescription,
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.orderDescription ? params.row.orderDescription : '—'}
        </Typography>
      ),
    },
    {
      field: 'createdDateTime',
      headerName: 'Date',
      sortable: false,
      width: 150,
      disableColumnMenu: true,
      filterOperators: dateRangeFilterOperators,
      type: 'date',
      valueGetter: (params: any) => new Date(params.row.orderDate),
      renderCell: (params: any) => (
        <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {formatDate(params.row.orderDate, false, 'dd MMM, HH:mm')}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => (
        <ActionMenu
          onDetailsClick={() => handleOrderDetailsClick(params)}
          onViewCustomerClick={() => handleViewCustomerClick(params)}
          tableType="salesOrders"
        />
      ),
      width: 20,
      align: 'center',
    },
  ];
  return columns;
};
export default OrderColumns;
