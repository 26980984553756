export const orders = {
  items: [
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 10000.0,
        isEstimated: false,
      },
      status: 2,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription:
        'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 70.0,
        isEstimated: false,
      },
      status: 1,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Phasellus diam lacus, pretium in dolor nec, facilisis aliquet justo. ',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 1200.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 1000.0,
        isEstimated: false,
      },
      status: 5,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 13200.0,
        isEstimated: false,
      },
      status: 0,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 3,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 101340.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
    {
      billingDetails: {
        firstName: 'billingFirstName',
        lastName: 'billingLastName',
        billingAddress: {
          addressLine1: 'addressLine1',
          addressLine2: null,
          addressLine3: null,
          city: 'city',
          state: null,
          stateCode: null,
          country: 'country',
          countryCode: null,
          county: null,
          postalCode: 'postalCode',
          phone: null,
          fax: null,
        },
        email: 'email',
        phone: null,
        dateOfBirth: null,
      },
      customer: {
        partnerKey: 'ONE',
        brandKey: 'OMC',
        memberKey: 'ONE-OMC-0570314',
        firstName: 'Denis',
        lastName: 'Lazau',
        email: 'email',
      },
      orderDate: '2024-09-12T11:32:55.0306392+03:00',
      orderDescription: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet',
      orderNumber: 'OrderNumber',
      orderTotal: {
        currency: 'USD',
        amount: 100.0,
        isEstimated: false,
      },
      status: 7,
      updateDate: '2024-09-12T11:32:55.0317114+03:00',
    },
  ],
  itemCount: 256,
  hasMoreItems: false,
  requestCriteria: {
    take: 25,
    skip: 0,
    searchTerm: null,
    sortField: null,
    isAscending: null,
  },
};
